<template>
    <textarea ref="textarea" v-model="input" class="w-full font-medium text-base tracking-[-0.64px] focus-visible:outline-none disabled:bg-transparent disabled:text-[#8C8C8C]" />
</template>

<script setup>
/**
 * BaseTextarea exposes a named model "input" which can be bound using `v-model:input` from the parent component
 * to establish 2 way data binding with the value specified in this textarea
 */
import { useTextareaAutosize } from "@vueuse/core";
import { useAttrs, watch } from "vue";
const attr = useAttrs();

const { textarea, input } = useTextareaAutosize({ styleProp: attr.rows ? "minHeight" : undefined });

// a model to two way bind with the parent consumer of this component, below watchers sync this model with the model on the textarea
const model = defineModel("input", { type: String, default: "" });
watch(
    model,
    (newValue) => {
        if (input.value !== newValue) {
            input.value = newValue;
        }
    },
    { immediate: true },
);
watch(input, (newValue) => {
    if (model.value !== newValue) {
        model.value = newValue;
    }
});

defineExpose({ textareaRef: textarea });
</script>

<style scoped>
textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

textarea::-webkit-scrollbar,
textarea::-webkit-resizer {
    display: none;
}
</style>
